import styles from 'mainStyles.module.css';
import SocialImage from './SocialImage';

const socialInfo = [
	{
		linkURL: 'https://play.google.com/store/apps/details?id=get.dukan.grocerydelivery.app',
		imageSrc: process.env.PUBLIC_URL + '/images/google_store.png',
	},
	{
		linkURL: 'https://apps.apple.com/app/dukan-grocery-food-delivery/id1586510758',
		imageSrc: process.env.PUBLIC_URL + '/images/apple_store.png',
	},
	{
		linkURL: 'https://appgallery.huawei.com/app/C105065617',
		imageSrc: process.env.PUBLIC_URL + '/images/app_gallery.png',
	},
];

const StoreLinks = () => (
	<div className={styles.social_image_block}>
		{socialInfo.map((socialProps, index) => <SocialImage key={index} {...socialProps} />)}
	</div>
);

export default StoreLinks;
