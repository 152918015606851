import { FC } from 'react';
import styles from 'mainStyles.module.css';

interface ISocialImageProps {
	imageSrc: string;
	linkURL: string;
}

const SocialImage: FC<ISocialImageProps> = ({ imageSrc, linkURL }) => (
	<a
		href={linkURL}
		target='_blank'
		rel='noreferrer'
		className={styles.social_image_wrap}
	>
		<img src={imageSrc} alt='x' />
	</a>
);

export default SocialImage;
