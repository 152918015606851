import styles from 'mainStyles.module.css';
import { TypeAnimation } from 'react-type-animation';

const countries = ['Sri Lanka', 2000, 'India', 2000, 'Pakistan', 2000, 'Egypt', 2000];

const AnimatedCenterSpan = () => (
	<div className={styles.center_title_animated_span}>
		<TypeAnimation
			cursor={true}
			sequence={countries}
			wrapper='span'
			repeat={Infinity}
		/>
	</div>
);

export default AnimatedCenterSpan;
