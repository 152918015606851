import { FC } from 'react';
import styles from 'mainStyles.module.css';
import AnimatedCenterSpan from './AnimatedCenterSpan';

const CenterTitle: FC = () => (
	<div className={styles.center_title_wrap}>
		<span>Food, Groceries from</span>
		{' '}
		<AnimatedCenterSpan />
		<p>delivered to your doorstep</p>
	</div>
);

export default CenterTitle;
