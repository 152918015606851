import { FC } from 'react';
import styles from 'mainStyles.module.css';

interface ISideBlockProps {
	imagePath: string;
}

const SideBlock: FC<ISideBlockProps> = ({ imagePath }) => (
	<div className={styles.side_wrap}>
		<img src={imagePath} alt='x' />
	</div>
);

export default SideBlock;
