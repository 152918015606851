import { useMediaQuery } from 'react-responsive';
import SideBlock from 'components/SideBlock';
import CenterBlock from 'components/CenterBlock';
import styles from 'mainStyles.module.css';

const App = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1050px)' });

    // ! render
    return (
        <div className={styles.page_wrap}>
            <div className={styles.main}>
                <SideBlock imagePath={isDesktop ? process.env.PUBLIC_URL + '/images/left_image.png' : process.env.PUBLIC_URL + '/images/left_image_small.png'} />

                <CenterBlock />

                <SideBlock imagePath={isDesktop ? process.env.PUBLIC_URL + '/images/right_image.png' : process.env.PUBLIC_URL + '/images/right_image_small.png'} />
            </div>
        </div>
    );
};

export default App;
