import CenterTitle from './CenterTitle';
import StoreLinks from './StoreLinks';
import styles from 'mainStyles.module.css';

const CenterBlock = () => (
    <div className={styles.center_wrap}>
        <div className={styles.center_main}>

            {/*MAIN TOP*/}
            <div className={styles.center_top_block}>
                {/*BANNER*/}
                <div className={styles.logo_wrap}>
                    <img src={process.env.PUBLIC_URL + '/images/banner_logo.png'} alt='logo' />
                </div>

                <CenterTitle />

                <StoreLinks />
            </div>

            {/*MAIN BOTTOM*/}
            <div className={styles.bottom_wrap}>
                <img src={process.env.PUBLIC_URL + '/images/app_mockup.png'} alt='x' />
            </div>
        </div>
    </div>
);

export default CenterBlock;
